import React from 'react';
import PropTypes from 'prop-types';

import Input from '../../../../../../components/input';
import { CurrencyInfoBlock } from '../CurrencyInfoBlock';

import { PRICELABELS, FUNDSFIELDS } from '../../../../../../bi/constants/trips';
import { HOTEL_PROVIDER_VALUE } from '../../../../../../bi/constants/hotel';
import COMPONENTS from '../../../../../../bi/constants/components';

import styles from '../../../../styles/form.module.scss';

const {
  INPUT: { TYPE: { NUMBER } },
} = COMPONENTS;

const Currency = ({
  currencyInfo,
  providerName,
  disabledInput,
  disabledEdit,
  onChangeInput,
  onBlur,
  valid,
  isEdit,
}) => {
  const { Base, Commission, CurrencyName, MFInCurrency, MF, Inclusive, RequiredTotalInRub } = currencyInfo;

  const isExpedia = providerName === HOTEL_PROVIDER_VALUE.expedia;
  const disabledPrice = disabledEdit || disabledInput;

  const renderMarketingFeeRub = () => {
    if (!isExpedia) return null;

    return (
      <div className={ styles['col-1-4'] }>
        <Input
          field={ FUNDSFIELDS.MF }
          type={ NUMBER }
          value={ MF }
          label={ PRICELABELS.MARKETING_FEE_RUB }
          onChange={ (e, field, value) => onChangeInput(field, value) }
          disabled
        />
      </div>
    );
  };

  const renderMarketingFee = () => {
    if (!isExpedia) return null;

    const validMF = !isEdit ? valid.CurrencyInfo.CurrencyMFInCurrency : '';

    return (
      <div className={ styles['col-1-4'] }>
        <Input
          field={ FUNDSFIELDS.MF_CURRENCY }
          type={ NUMBER }
          value={ MFInCurrency }
          label={ PRICELABELS.MARKETING_FEE }
          onChange={ (e, field, value) => onChangeInput(field, value) }
          onBlur={ (e, field) => onBlur(e, field) }
          disabled={ disabledEdit }
          valid={ validMF }
        />
      </div>
    );
  };

  const renderInclusive = () => {
    if (!isExpedia || isEdit) return null;

    const validInclusive = !isEdit ? valid.CurrencyInfo.CurrencyInclusive : '';

    return (
      <div className={ styles['col-1-4'] }>
        <Input
          field={ FUNDSFIELDS.INCLUSIVE }
          type={ NUMBER }
          value={ Inclusive }
          label={ PRICELABELS.INCLUSIVE }
          onChange={ (e, field, value) => onChangeInput(field, value) }
          onBlur={ (e, field) => onBlur(e, field) }
          disabled={ disabledEdit }
          valid={ validInclusive }
        />
      </div>
    );
  };

  const renderTotalSum = () => {
    if (!isExpedia || isEdit) return null;

    return (
      <div className={ styles['col-1-4'] }>
        <Input
          field={ FUNDSFIELDS.TOTAL_RUB }
          type={ NUMBER }
          value={ RequiredTotalInRub }
          label={ PRICELABELS.TOTAL_RUB }
          onChange={ (e, field, value) => onChangeInput(field, value) }
          onBlur={ (e, field) => onBlur(e, field) }
          disabled={ disabledEdit }
        />
      </div>
    );
  };

  const validateBase = !isEdit && isExpedia ? valid.CurrencyInfo.CurrencyBase : '';
  const validateCommission = !isEdit && isExpedia ? valid.CurrencyInfo.CurrencyCommission : '';

  return (
    <div className={ styles.row }>
      <div className={ styles.row }>
        <div className={ styles['col-1-4'] }>
          <Input
            field={ FUNDSFIELDS.BASE }
            type={ NUMBER }
            value={ Base }
            label={ PRICELABELS.CURRENCY_BASE(CurrencyName) }
            onChange={ (e, field, value) => onChangeInput(field, value) }
            disabled={ disabledPrice }
            valid={ validateBase }
          />
        </div>
        <div className={ styles['col-1-4'] }>
          <Input
            field={ FUNDSFIELDS.COMMISSION }
            type={ NUMBER }
            value={ Commission }
            label={ PRICELABELS.CURRENCY_COMMISSION(CurrencyName) }
            onChange={ (e, field, value) => onChangeInput(field, value) }
            disabled={ disabledPrice }
            valid={ validateCommission }
          />
        </div>
        <CurrencyInfoBlock
          wrapperClassName={ styles['col-1-4'] }
          currency={ currencyInfo }
        />
      </div>
      <div className={ styles.row }>
        { renderMarketingFeeRub() }
        { renderMarketingFee() }
        { renderInclusive() }
        { renderTotalSum() }
      </div>
    </div>
  );
};

Currency.propTypes = {
  currencyInfo: PropTypes.object.isRequired,
  providerName: PropTypes.string.isRequired,
  disabledInput: PropTypes.bool,
  disabledEdit: PropTypes.bool,
  onChangeInput: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  valid: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
};

Currency.defaultProps = {
  disabledInput: false,
  disabledEdit: false,
  isEdit: false,
};

export { Currency };
