import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button } from 'sw-ui';
import moment from 'moment';

import AjaxButton from '../../../../components/ajaxButton';
import Input from '../../../../components/input';
import Checkbox from '../../../../components/checkbox';
import { FlatButton } from '../../../../components/button';
import AlertTP from '../../../../components/tpDetails/alert';
import { Nights } from './nights';
import { Currency } from './components/Currency';

import {
  diffDays,
  getMoment,
  isBeforeDate,
  isAfterDate,
  isValidMomentObject,
  timeWithoutMoment,
  formatDate,
  momentObject,
  isMoment,
  momentAddHoursMinutes,
} from '../../../../bi/utils/formatDate';
import { findDepartments, isIntNumberOrDash, preventKeyNotNumber } from '../../../../bi/utils/trip';
import { applyAnalytics, removeAnalytics, validateSingleAnalytics, validateAnalytics } from '../../../../bi/utils/customAnalytics';
import { fixedNumber } from '../../../../bi/utils/number';
import { isMandatory } from '../../../../bi/utils/account';
import { dateAfterOtherDate, getRegionId, prepareMealForSave } from '../../../../bi/utils/hotels';
import scrollToErrMessage from '../../../../utils/scrollToErrMessage';

import {
  FIELDS,
  FUNDSFIELDS,
  FUNDSFIELDSTYPES,
  FUNDSLABELS,
  PRICEFIELDSSHORT,
  PRICELABELS,
  TRIPSLABELS,
  INN_LENGHTS,
} from '../../../../bi/constants/trips';
import COMPONENTS from '../../../../bi/constants/components';
import { HOTEL_PROVIDER_VALUE, ROOM_TYPE, PROVIDERS_AVAILABLE_INN, HOTEL_PROVIDER_IS_AUTO_CALC_VAT } from '../../../../bi/constants/hotel';
import { numbersAndColon, timePattern } from '../../../../bi/constants/regExp';
import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { DATE_WITHOUT_TIME_ZONE, FULLTIME, DATE } from '../../../../constants/time';
import { ERRORSFORALL } from '../../../../bi/constants/tripValidator';
import { VATVALUE, MEAL_LIST_TYPE } from '../../../../bi/constants/hotels';

import renderFundsForm from '../form';

import CommonFields from './commonFields';
import { VatBlock } from './compoments/VatBlock';

import styles from '../../styles/form.module.scss';

const ERRORS = {
  INVALID_CHARACTER: 'недопустимые символы',
  INVALID_FORMAT: 'недопустимый формат',
};

const BUTTONS = {
  RECALCULATE: 'Пересчитать',
};

const FIELD_VALUE = 'Питание не включено';

const LABELS = {
  FINANCE_OPERATIONS: 'Финансовые операции',
  UPDATE_CANCELLATION_CONDITION: 'Обновите условие штрафа',
  UPDATE_VAT: 'Обновите НДС',
  ALERT_BOOKING_DATE: 'Требуется изменить дату снятия брони без потерь',
  OK: 'Ok',
};

const {
  BUTTON: { THEME: { FLAT } },
  INPUT: { TYPE: { NUMBER } },
} = COMPONENTS;

class HotelForm extends Component {
  static propTypes = {
    hotel: PropTypes.object.isRequired,
    accountId: PropTypes.object.isRequired,
    tripService: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    projects: PropTypes.arrayOf(PropTypes.object),
    travelPolicyService: PropTypes.object.isRequired,
    analytics: PropTypes.array,
    tripAnalyticsValueIds: PropTypes.array,
    setTripAnalytics: PropTypes.func,
    notificationsService: PropTypes.object.isRequired,
    mandatoryProject: PropTypes.array.isRequired,
  };

  static defaultProps = {
    employees: [],
    projects: [],
    analytics: [],
    tripAnalyticsValueIds: [],
    setTripAnalytics: () => {},
  };

  constructor(props) {
    super(props);

    this.state = this.getHotel(props.hotel);
    this.state.errors = {
      'Hotel.CheckinTime': '',
      'Hotel.CheckoutTime': '',
    };

    this.providerNameOptions = props.tripService.getHotelProviderName();
  }

  UNSAFE_componentWillMount() {
    this.setState({
      EmployeesList: this.state.Employees,
    }, this.getTPForAll);
  }

  componentDidMount() {
    this.getPricesPerNightAndTLRates();
    this.unsubscribe = this.props.tripService.subscribe(this.updateTripData);
    this.setDepartment();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.hotel.OrderTripItemId !== this.state.OrderTripItemId) {
      this.setState(this.getHotel(newProps.hotel));
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateTripData = ({ employeesList }) => this.setState({ employeesList });

  getPricesPerNightAndTLRates = async () => {
    const { tripService: { getHotelOrderInfo, getTravellineRates }, hotel: { JsonData } } = this.props;
    const { ProviderName, ReservationNumber, IsCustom, Hotel: { ClassificatorId }, Room: { Category } } = JSON.parse(JsonData);

    if (ProviderName === HOTEL_PROVIDER_VALUE.travelline) {
      const { RateInfo: { Price, BookInfo: { RoomTypeId, PlanId } } } = await getHotelOrderInfo(ReservationNumber, ProviderName, IsCustom);
      const { rates } = await getTravellineRates(ClassificatorId);
      const roomCategoryName = Category || rates.find(({ id }) => id === RoomTypeId).rate_name;

      this.setState(prevState => ({
        ...prevState,
        rates,
        ratePlaneId: RoomTypeId,
        planId: PlanId,
        JsonData: {
          ...prevState.JsonData,
          PerDayPrices: Price.PerDayPrices || [],
          Room: {
            ...this.state.JsonData.Room,
            Category: roomCategoryName,
          },
        },
      }));
    }
  }

  setDepartment = async () => {
    const { EmployeeId, CompanyId } = this.state;
    const { tripService: { getEmployeeDataByID } } = this.props;
    const currentEmployee = await getEmployeeDataByID(EmployeeId);

    this.setState({ Departments: findDepartments(currentEmployee, CompanyId) });
  }

  getHotel(hotel) {
    const { mandatoryProject, tripService } = this.props;
    const {
      Employees,
      OrderTripItemId,
      ServiceType,
      CompanyId,
      ProjectId,
      DepartmentId,
      JsonData,
      SupplierDocument,
      ProviderInn,
      ReportAmount,
      UserAnalytics = [],
      CurrencyInfo,
    } = hotel;

    const currentEmployeeId = hotel && Employees && Employees[0] && Employees[0].Id;
    const newMandatory = isMandatory(mandatoryProject, CompanyId || 0);

    const jsonData = JSON.parse(JsonData);

    const { CancellationPenalties = [], Meal: { Category } } = jsonData.Room;
    const { CountryCode } = jsonData.Hotel;

    const isExpediaOrTripcomProvider =
      jsonData.ProviderName === HOTEL_PROVIDER_VALUE.tripcom ||
      jsonData.ProviderName === HOTEL_PROVIDER_VALUE.expedia;

    const isAllVatReady = tripService.checkAllVersionsVatReady(hotel);

    const vatReady = !(hotel.InternalVat.VatReady && isAllVatReady);
    const vatAmount = hotel.InternalVat.VatReady && isAllVatReady
      ? tripService.getSumVatAmount(hotel)
      : 0;
    const rate = hotel.InternalVat.VatReady ? tripService.getMaxRate(hotel) : 0;
    const hasVat = jsonData.PriceDetails.HasVAT ? VATVALUE.WITH : VATVALUE.WITHOUT;

    const state = {
      CurrencyInfo,
      OrderTripItemId,
      ServiceType,
      Description: '',
      Status: 1,
      Employees,
      EmployeesList: [{}],
      CompanyId: CompanyId || 0,
      ProjectId: ProjectId || 0,
      UserAnalytics,
      ProviderInn,
      ReportAmount,
      mandatoryProject: newMandatory,
      hotelName: {
        label: jsonData.Hotel.Name || '',
        selected: {},
        suggests: [],
      },
      Meal: MEAL_LIST_TYPE.find(({ value }) => value === Category).list,
      CustomHotelName: jsonData.Hotel.Name || '',
      CountryCode: CountryCode || '',
      DepartmentId: DepartmentId || 0,
      EmployeeId: currentEmployeeId,
      Departments: [],
      Penalties: {
        Enabled: false,
        Description: '',
        Penalties: 0,
        Tax: 0,
      },
      ReturnFunds: {
        Enabled: false,
        Base: 0,
        Commission: 0,
        Description: '',
      },
      Surcharges: {
        Enabled: false,
        Base: 0,
        Commission: 0,
        Description: '',
      },
      JsonData: jsonData,
      CancellationPenalties: CancellationPenalties.map((item) => {
        const penalty = item;
        penalty.From = moment(penalty.From);
        return penalty;
      }) || [{}],
      Contact: SupplierDocument ? SupplierDocument.Contact : '',
      isReturnFunds: true,
      PriceDetails: {
        Base: jsonData.PriceDetails.Base,
        hasVat,
      },

      tpDetailsSecond: null,
      showAlertTP: false,
      showAlertBookingDate: false,

      validation: {
        ProjectId: '',
        Description: '',
        EmployeeFirst: '',
        ReservationNumber: '',
        ReservationDate: '',
        GuestCount: '',
        CheckinDate: '',
        CheckoutDate: '',
        Hotel: {
          Name: '',
          Address: '',
          City: '',
        },
        Total: '',
        From: '',
        Penalty: {
          Base: '',
        },
        BreakfastName: '',
      },
      isExpediaOrTripcomProvider,
      InternalVat: hotel.InternalVat,
      employeesList: [],
      analytics: {},
      rates: [],
      vatReady,
      vatAmount,
      rate,
      disabledVatReady: !hotel.InternalVat.VatReady,
    };

    const { BasePrice, ...jsonDataWithoutBasePrice } = state.JsonData.PriceDetails;

    state.JsonData.PriceDetails = jsonDataWithoutBasePrice;
    state.JsonData.CheckinDate = momentObject(formatDate(state.JsonData.CheckinDate, DATE));
    state.JsonData.CheckoutDate = momentObject(formatDate(state.JsonData.CheckoutDate, DATE));
    state.JsonData.ReservationDate = moment(state.JsonData.ReservationDate);
    state.JsonData.Room.FreeCancellation = state.JsonData.Room.FreeCancellation
      ? moment.utc(state.JsonData.Room.FreeCancellation)
      : null;

    return state;
  }

  getValidationCancellationPenalties = (reset = false) => {
    const { CancellationPenalties, validation } = this.state;
    const text = reset ? '' : LABELS.UPDATE_CANCELLATION_CONDITION;

    const newValidation = validation;
    newValidation.Total = CancellationPenalties.map(() => text);
    newValidation.Penalty.Base = CancellationPenalties.map(() => text);

    return newValidation;
  }

  normalizeDate(JsonData) {
    const newJsonData = JsonData;
    const { ProviderName } = JsonData;
    const checkDate = formatDate(newJsonData.CheckinDate, FULLTIME);
    const checkoutDate = formatDate(newJsonData.CheckoutDate, FULLTIME);
    const reservationDate = typeof newJsonData.ReservationDate === 'string'
      ? formatDate(newJsonData.ReservationDate, FULLTIME)
      : newJsonData.ReservationDate;
    const roomCancel = newJsonData.Room.FreeCancellation;
    const cancellationPenalty = this.state.CancellationPenalties.map(({ From, Total, Base, Additional }) => ({
      From: From.format(FULLTIME),
      Base,
      Total: parseFloat(Total, 10),
      Additional,
    }));

    if (ProviderName !== HOTEL_PROVIDER_VALUE.travelline) {
      const isValidRoomCancel = roomCancel ? timeWithoutMoment(formatDate(roomCancel, FULLTIME), FULLTIME) : null;
      newJsonData.Room.FreeCancellation = roomCancel ? isValidRoomCancel : roomCancel;
    } else {
      const isValidRoomCancelTravelLine = roomCancel ? formatDate(roomCancel, DATE_WITHOUT_TIME_ZONE) : null;
      newJsonData.Room.FreeCancellation = roomCancel ? isValidRoomCancelTravelLine : roomCancel;
    }
    newJsonData.CheckinDate = checkDate || null;
    newJsonData.CheckoutDate = checkoutDate || null;
    newJsonData.ReservationDate = reservationDate ? reservationDate.format(FULLTIME) : reservationDate;
    newJsonData.Room.CancellationPenalties = cancellationPenalty;
    newJsonData.PriceDetails.EarlyCheckIn = parseFloat(newJsonData.PriceDetails.EarlyCheckIn, 10) || 0;
    newJsonData.PriceDetails.LateCheckOut = parseFloat(newJsonData.PriceDetails.LateCheckOut, 10) || 0;
    newJsonData.PriceDetails.Fee = parseFloat(newJsonData.PriceDetails.Fee, 10) || 0;
    newJsonData.PriceDetails.Tax = parseFloat(newJsonData.PriceDetails.Tax, 10) || 0;
    newJsonData.PriceDetails.Commission = parseFloat(newJsonData.PriceDetails.Commission, 10) || 0;
  }

  getTPForAll() {
    const { travelPolicyService } = this.props;
    const { EmployeesList } = this.state;
    const HOTEL = travelPolicyService.getServiceTypeConst().HOTEL;

    const newEmployeesList = EmployeesList.map(item => ({ ...item }));

    EmployeesList.forEach((item, index) => {
      travelPolicyService.getEmployeeTP(item.Id)
        .then((res) => {
          newEmployeesList[index].tpDetails = travelPolicyService.getTPDetails(res, HOTEL);

          this.setState({
            EmployeesList: newEmployeesList,
            showAlertTP: true,
          });
        });
    });
  }

  handleAddCancellation = () => {
    const newCancellationPenalties = [...this.state.CancellationPenalties];

    newCancellationPenalties.push({ From: null, Total: '', Base: '', Additional: false, IsCustomCancellation: true });

    this.setState({
      CancellationPenalties: newCancellationPenalties,
    });
  };

  handleRemoveCancellation = (i) => {
    const { ReturnFunds, Surcharges, validation } = this.state;
    const newCancellationPenalties = [...this.state.CancellationPenalties];

    if (ReturnFunds.Enabled || Surcharges.Enabled) {
      this.setState({
        validation: {
          ...validation,
          Total: this.handleDeleteObject(validation.Total, i),
          Penalty: {
            Base: this.handleDeleteObject(validation.Penalty.Base, i),
          },
        },
      });
    }

    this.setState({
      CancellationPenalties: this.handleDeleteObject(newCancellationPenalties, i),
    });
  };

  handleAddNights = () => {
    const perDayPrices = [...this.state.JsonData.PerDayPrices || []];

    perDayPrices.push({ Date: null, Price: '' });

    this.setState({
      JsonData: {
        ...this.state.JsonData,
        PerDayPrices: perDayPrices,
      },
    });
  };

  handleRemoveNights = (i) => {
    const perDayPrices = [...this.state.JsonData.PerDayPrices];

    const dayList = this.handleDeleteObject(perDayPrices, i);

    this.setState({
      JsonData: {
        ...this.state.JsonData,
        PerDayPrices: dayList,
      },
    });
  };

  isCustomDate = (firstDate, secondDate) => {
    const newDate = firstDate;
    const oldDate = secondDate;

    if (isAfterDate(newDate, oldDate)) {
      return FIELDS.CUSTOMCHECKOUT;
    }

    if (isBeforeDate(newDate, oldDate)) {
      return FIELDS.CUSTOMCHECKIN;
    }

    return false;
  }

  checkAndUpdateNights = (value, perDayPrices) => {
    const { CheckinDate, CheckoutDate } = this.state.JsonData;
    const newNight = momentObject(value).add(1, 'd');

    if (this.isCustomDate(newNight, CheckoutDate) === FIELDS.CUSTOMCHECKOUT) {
      return this.setState({
        JsonData: {
          ...this.state.JsonData,
          CheckinDate,
          CheckoutDate: newNight,
          PerDayPrices: perDayPrices,
        },
      });
    }

    if (this.isCustomDate(value, CheckinDate) === FIELDS.CUSTOMCHECKIN) {
      return this.setState({
        JsonData: {
          ...this.state.JsonData,
          CheckinDate: value,
          CheckoutDate,
          PerDayPrices: perDayPrices,
        },
      });
    }

    return this.setState({
      JsonData: {
        ...this.state.JsonData,
        PerDayPrices: perDayPrices,
      },
    });
  }

  handleChangeNights = (field, value, i) => {
    const perDayPrices = [...this.state.JsonData.PerDayPrices];
    perDayPrices[i][field] = fixedNumber(value, 2);

    if (field === FIELDS.DATE) {
      return this.checkAndUpdateNights(value, perDayPrices);
    }

    return this.setState({
      JsonData: {
        ...this.state.JsonData,
        PerDayPrices: perDayPrices,
      },
    });
  };

  handleChangeInput = (e, field, value) => this.setState({
    [field]: value,
  });

  handleDeleteObject = (items, index) => [
    ...items.slice(0, index),
    ...items.slice(index + 1),
  ];

  handleChangeFinancesEnabled = (e, field, value) => {
    const { JsonData, InternalVat, vatReady } = this.state;
    const calcType = InternalVat.VatReady ? InternalVat.VatInfo.CalcType : 0;
    const isAuto = HOTEL_PROVIDER_IS_AUTO_CALC_VAT.includes(JsonData.ProviderName);
    const showAmount = ((!isAuto && !vatReady) || !vatReady) && calcType !== 1;

    this.setState((state) => {
      const newState = state;
      const part = field.split('.');

      newState[part[0]][part[1]] = value;

      if (field === FUNDSFIELDS.RETURNFUNDS || field === FUNDSFIELDS.SURCHARGES) {
        if (value) {
          newState.validation = this.getValidationCancellationPenalties();
        }

        if (!value && (!newState.Surcharges.Enabled && !newState.ReturnFunds.Enabled)) {
          newState.validation = this.getValidationCancellationPenalties(true);
        }

        newState.validation.HasVat = showAmount ? LABELS.UPDATE_VAT : '';
      }

      return newState;
    });
  }

  handlerChangePenalties = (e, field, value) => this.setState((state) => {
    const newState = state;
    newState.Penalties[field] = value;

    return newState;
  });

  handlerChangeReturnFunds = (e, field, value) => this.setState((state) => {
    const newState = state;
    newState.ReturnFunds[field] = value;

    return newState;
  });

  handlerChangeSurcharges = (e, field, value) => this.setState((state) => {
    const newState = state;
    newState.Surcharges[field] = value;

    return newState;
  });

  resetMainHotelFields = () => {
    this.setState({
      hotelName: {
        label: '',
        selected: {},
        suggests: [],
      },
      CustomHotelName: '',
      contract: {},
      connected: {},
      compared: {},
      JsonData: {
        ...this.state.JsonData,
        Hotel: {
          ...this.state.JsonData.Hotel,
          Name: '',
          Address: '',
          City: '',
          Phone: '',
          Stars: 0,
          CheckinTime: '',
          CheckoutTime: '',
          ClassificatorId: null,
        },
      },
    });
  }

  handleChangeJsonInput = (e, field, value) => this.setState((state) => {
    const newState = state;
    const part = field.split('.');

    if (part.length === 1) {
      newState.JsonData[field] = value;
    } else {
      newState.JsonData[part[0]][part[1]] = value;
    }

    return newState;
  });

  handleChangeNumberInput = (field, value) => {
    if ((value === '' || isIntNumberOrDash(value)) && value.length <= INN_LENGHTS.MAX) {
      this.setState({
        [field]: value,
      });
    }
  }

  handleChangeRoomCategory = (e, field, rateId) => {
    const { rates } = this.state;
    const { rate_name } = rates.find(({ id }) => id === rateId);

    this.setState({ ratePlaneId: rateId });
    this.handleChangeJsonInput(e, field, rate_name);
  };

  handleChangeProviderName = (e, field, value) => {
    const { rates } = this.state;

    if (!rates && value === HOTEL_PROVIDER_VALUE.travelline) {
      this.getTravellineRates();
    }

    this.handleChangeJsonInput(e, field, value);

    this.setState({
      ProviderInn: '',
    });
  };

  handleChangeCheckbox = (e, field, value) => this.setState((state) => {
    const newState = state;
    newState.JsonData[field] = value;

    return newState;
  });

  handleChangeECLCCheckbox = (e, field, value) => {
    const {
      JsonData,
      JsonData: {
        CustomCheckInDate,
        CustomCheckOutDate,
        PriceDetails,
      },
    } = this.state;

    let customCheckInDate = CustomCheckInDate || false;
    let customCheckOutDate = CustomCheckOutDate || false;
    const priceField = field === 'CustomCheckInDate' ? 'EarlyCheckIn' : 'LateCheckOut';

    if (field === FIELDS.CUSTOMCHECKINDATE) {
      customCheckInDate = value;
    }

    if (field === FIELDS.CUSTOMCHECKOUTDATE) {
      customCheckOutDate = value;
    }

    this.setState({
      JsonData: {
        ...JsonData,
        CustomCheckInDate: customCheckInDate,
        CustomCheckOutDate: customCheckOutDate,
        [field]: value,
        PriceDetails: {
          ...PriceDetails,
          [priceField]: value ? PriceDetails[priceField] : 0,
        },
      },
      showAlertBookingDate: field === FIELDS.CUSTOMCHECKINDATE ? customCheckInDate : false,
    });
  };

  handleChangeDate = (field, value) => {
    const { JsonData: { CheckinDate } } = this.state;

    this.setState((state) => {
      const newState = state;
      const part = field.split('.');

      if (part.length === 1) {
        newState.JsonData[field] = value;
        newState.showAlertBookingDate = field === FIELDS.CHECKIN_DATE ? isBeforeDate(value, CheckinDate) : false;
      } else {
        newState.JsonData[part[0]][part[1]] = value;
      }

      return newState;
    }, () => this.handleSetNightsCount());
  };

  handleSetNightsCount = () => {
    const { JsonData: { CheckinDate, CheckoutDate } } = this.state;

    if (isValidMomentObject(CheckinDate) && isValidMomentObject(CheckoutDate)) {
      const diff = diffDays(CheckinDate, CheckoutDate);
      this.handleChangeJsonInput(null, FIELDS.NIGHTS_COUNT, diff);
    }
  };

  handleChangePriceDetailsInput = (e, field, value) => {
    const { JsonData, InternalVat, vatReady } = this.state;
    const calcType = InternalVat.VatReady ? InternalVat.VatInfo.CalcType : 0;
    const isAuto = HOTEL_PROVIDER_IS_AUTO_CALC_VAT.includes(JsonData.ProviderName);
    const showAmount = ((!isAuto && !vatReady) || !vatReady) && calcType !== 1;

    this.setState({
      JsonData: {
        ...JsonData,
        PriceDetails: {
          ...JsonData.PriceDetails,
          [field]: fixedNumber(value, 2),
        },
      },
      validation: {
        ...this.state.validation,
        HasVat: showAmount ? LABELS.UPDATE_VAT : '',
      },
    });
  };

  getRoomTypeId = () => {
    const { JsonData: { Breakfast, Room: { FreeCancellation } } } = this.state;
    let roomTypeId = null;

    const isFreeCancellationAfterDate = isAfterDate(FreeCancellation, getMoment());
    const isFreeCancellation = FreeCancellation && isFreeCancellationAfterDate;

    if (!isFreeCancellation && !Breakfast) {
      roomTypeId = ROOM_TYPE.WITHOUT_CANCELLATION_AND_BREAKFAST;
    }

    if (!isFreeCancellation && Breakfast) {
      roomTypeId = ROOM_TYPE.BREAKFAST_WITHOUT_CANCELLATION;
    }

    if (isFreeCancellation && !Breakfast) {
      roomTypeId = ROOM_TYPE.CANCELLATION_WITHOUT_BREAKFAST;
    }

    if (isFreeCancellation && Breakfast) {
      roomTypeId = ROOM_TYPE.WITH_CANCELLATION_AND_BREAKFAST;
    }

    return roomTypeId;
  };

  getBookInfo = () => {
    const {
      JsonData: {
        PerDayPrices = [],
        Breakfast,
        ProviderName,
        IsCustom,
      },
      CancellationPenalties,
    } = this.state;
    const { ratePlaneId } = this.state;
    const roomTypeId = ProviderName === HOTEL_PROVIDER_VALUE.travelline ? this.definitionRatePlanId(CancellationPenalties, Breakfast, IsCustom) : this.getRoomTypeId();
    const perDayPricesList = PerDayPrices.map(({ Date, Price }) => ({
      Date: formatDate(Date, DATE_WITHOUT_TIME_ZONE),
      Price,
    }));

    return {
      RoomTypeId: ratePlaneId,
      PlanId: roomTypeId,
      PerDayPrices: perDayPricesList,
    };
  };

  handleConfirm = () => {
    const {
      OrderTripItemId,
      Description,
      Surcharges: {
        Enabled: SurchEnabled,
        Base: SurchBase,
        Commission: SurchCommission,
        Description: SurchDescription,
      },
      ReturnFunds: {
        Enabled: RetEnabled,
        Commission: RetCommission,
        Description: RetDescription,
        Base: RetBase,
      },
      Penalties: {
        Enabled: PenEnabled,
        Description: PenDescription,
        Penalties: PenPenalties,
        Tax: PenTax,
      },
      ServiceType,
      Status,
      JsonData,
      EmployeesList,
      CompanyId,
      ProjectId,
      DepartmentId,
      Contact,
      UserAnalytics,
      CountryCode,
      ProviderInn,
      ReportAmount,
      vatReady,
      vatAmount,
      rate,
      InternalVat,
      Meal,
    } = this.state;
    const Employee = EmployeesList[0];

    const { isValid, validation, errors } = this.validationSubmit({
      Description,
      Employee,
      JsonData,
      DepartmentId,
      ProjectId,
      InternalVat,
      vatReady,
      vatAmount,
    });

    if (isValid) {
      const employees = [];
      const bookInfo = this.getBookInfo();

      EmployeesList.forEach((item) => {
        if (item.Id) employees.push(item.Id);
      });

      JsonData.EmployeeIds = employees;
      JsonData.BookInfo = bookInfo;
      JsonData.NightsPrice = JsonData.NightsPrice || 0;
      JsonData.Room.FreeCancellation = JsonData.Room.FreeCancellation || null;

      if (JsonData.ProviderName === HOTEL_PROVIDER_VALUE.ostrovok && CountryCode.length && CountryCode !== 'RU') {
        JsonData.BreakfastName = JsonData.Breakfast ? JsonData.BreakfastName : FIELD_VALUE;
      }

      this.normalizeDate(JsonData);

      const penalties = PenEnabled && (PenPenalties || PenTax) ? [{
        Description: PenDescription,
        Penalties: parseFloat(PenPenalties, 10) || 0,
        Tax: parseFloat(PenTax, 10) || 0,
      }] : [];

      const isEmptyReturnFunds = RetBase !== '';
      const returnFunds = RetEnabled && isEmptyReturnFunds ? [{
        Base: parseFloat(RetBase, 10) || 0,
        Commission: parseFloat(RetCommission, 10) || 0,
        Description: RetDescription,
      }] : [];

      const surcharges = SurchEnabled && (SurchBase || SurchCommission) ? [{
        Base: parseFloat(SurchBase, 10) || 0,
        Commission: parseFloat(SurchCommission, 10) || 0,
        Description: SurchDescription,
      }] : [];

      const vatInfo = vatReady ? null : {
        HasVat: true,
        RackRate: 0,
        VatDetails: InternalVat.VatInfo.HasVat ? [{
          Rate: rate,
          Amount: vatAmount,
        }] : [],
      };

      const internalVat = {
        VatReady: !vatReady,
        VatInfo: vatInfo,
      };

      const item = {
        OrderTripItemId,
        TripId: 0,
        CompanyId: parseInt(CompanyId, 10),
        ProjectId: parseInt(ProjectId, 10),
        DepartmentId: parseInt(DepartmentId, 10),
        Description,
        Penalties: penalties,
        ReturnFunds: returnFunds,
        Surcharges: surcharges,
        Status,
        ServiceType,
        Contact,
        UserAnalytics,
        ProviderInn,
        Meal: prepareMealForSave(Meal),
        ReportAmount,
        JsonData: JSON.stringify(JsonData),
        InternalVat: internalVat,
      };

      this.props.onConfirm(item);
    } else {
      scrollToErrMessage();
      this.setState({
        validation: {
          ...validation,
        },
        errors,
      });
    }
  };

  handleChangeEmployee = async (value) => {
    const { CompanyId } = this.state;
    await this.props.tripService.autocompleteEmployees(CompanyId, value);
  }

  handleSelectEmployee = async (item, index) => {
    const { travelPolicyService, tripService } = this.props;

    const currentEmployee = await tripService.getEmployeeDataByID(item.Id);

    const { EmployeesList, validation: { EmployeeFirst: validationEmployeeFirst }, CompanyId, Departments } = this.state;

    const HOTEL = travelPolicyService.getServiceTypeConst().HOTEL;
    const newEmployeesList = [...EmployeesList];

    newEmployeesList[index] = currentEmployee;

    const valid = index === 0 ? '' : validationEmployeeFirst;

    const cb = () => {
      travelPolicyService.getEmployeeTP(item.Id)
        .then((res) => {
          const tpDetails = travelPolicyService.getTPDetails(res, HOTEL);

          newEmployeesList[index] = {
            ...newEmployeesList[index],
            tpDetails,
          };

          this.setState({
            EmployeesList: newEmployeesList,
            showAlertTP: Boolean(tpDetails),
          });
        });
    };

    this.setState({
      EmployeesList: newEmployeesList,
      Departments: index === 0 ? findDepartments(currentEmployee, CompanyId) : Departments,
      validation: {
        ...this.state.validation,
        EmployeeFirst: valid,
      },
    }, cb);
  };

  handleEmployeeFocus = async () => {
    const { CompanyId } = this.state;
    await this.props.tripService.autocompleteEmployees(CompanyId, '');
  };

  handleRemoveEmployeeSuggest = (index) => {
    const { EmployeesList, DepartmentId, Departments } = this.state;

    const newEmployeesList = EmployeesList.map((item, itemIndex) => {
      if (itemIndex === index) return {};

      return item;
    });

    const noEmployees = newEmployeesList.every(({ Id }) => !Id);

    this.setState({
      EmployeesList: newEmployeesList,
      DepartmentId: noEmployees ? 0 : DepartmentId,
      Departments: noEmployees ? [] : Departments,
    });
  };

  handleSelectProject = ({ id }) => {
    const { tripService } = this.props;
    const { mandatoryProject } = this.state;
    const result = mandatoryProject ? tripService.customTripFieldValidation(FIELDS.PROJECTID, id) : '';

    this.setState({
      ProjectId: id,
      validation: {
        ...this.state.validation,
        ProjectId: result,
      },
    });
  };

  handleUpdateMainHotelFields = ({
    Hotel: {
      Name,
      Address,
      City,
      RegionId,
      Phone,
      Stars,
      CheckinTime,
      CheckoutTime,
      CountryCode,
    },
    ProviderName,
    Contact,
  }) => {
    this.setState({
      CustomHotelName: Name,
      JsonData: {
        ...this.state.JsonData,
        ProviderName,
        Contact,
        Hotel: {
          ...this.state.JsonData.Hotel,
          Name,
          Address,
          City,
          RegionId: getRegionId(RegionId),
          Phone,
          Stars,
          CheckinTime,
          CheckoutTime,
          CountryCode,
        },
      },
    }, () => {
      this.validateInput(null, FIELDS.HOTELNAMEUPPER);
      this.validateInput(null, FIELDS.HOTELADDRESS);
      this.validateInput(null, FIELDS.HOTELCITY);
    });
  }

  handleSelectAnalytics = ({ analytics: currentAnalytics = {}, analyticsValueId = [] }) => {
    const { tripAnalyticsValueIds: selectedTripAnalytics, setTripAnalytics } = this.props;
    const { UserAnalytics: selectedServiceAnalytics = [], validation } = this.state;

    const { ApplyToTrip } = currentAnalytics;
    const analyticsValues = ApplyToTrip ? selectedTripAnalytics : selectedServiceAnalytics;

    const updatedAnalyticsValues = !analyticsValueId
      ? removeAnalytics(analyticsValues, currentAnalytics)
      : applyAnalytics(analyticsValueId, analyticsValues, currentAnalytics);

    const analyticsValidation = validateSingleAnalytics(updatedAnalyticsValues, currentAnalytics);

    if (ApplyToTrip) {
      setTripAnalytics(updatedAnalyticsValues);
    }

    const updatedServiceAnalytics = ApplyToTrip
      ? {}
      : {
        UserAnalytics: updatedAnalyticsValues,
      };

    this.setState({
      ...updatedServiceAnalytics,
      validation: {
        ...validation,
        analytics: {
          ...validation.analytics,
          ...analyticsValidation,
        },
      },
    });
  };

  handleSelectDepartment = ({ id }) => {
    const { tripService } = this.props;
    const { Departments } = this.state;
    const result = Departments.length ? tripService.hotelTripFieldValidation(FIELDS.DEPARTMENTID, id) : '';

    this.setState({
      DepartmentId: id,
      validation: {
        ...this.state.validation,
        DepartmentId: result,
      },
    });
  };

  handleSelectSuggest = (field, value, hotel, e) => {
    const { tripService } = this.props;

    tripService.getHotelDetails(hotel.Id).then((res) => {
      const { Name, Address, City, Phone, Stars, CheckinTime, CheckoutTime, ClassificatorId, CountryCode, RegionId } = res;

      this.setState({
        ...this.state,
        hotelName: {
          ...this.state.hotelName,
          label: Name,
        },
        CustomHotelName: Name,
        CountryCode,
        JsonData: {
          ...this.state.JsonData,
          Hotel: {
            ...this.state.JsonData.Hotel,
            Name,
            Address,
            City,
            RegionId: getRegionId(RegionId),
            Phone,
            Stars,
            CheckinTime,
            CheckoutTime,
            ClassificatorId: ClassificatorId || null,
          },
        },
      }, () => {
        this.validateInput(e, FIELDS.HOTELNAMEUPPER);
        this.validateInput(e, FIELDS.HOTELADDRESS);
        this.validateInput(e, FIELDS.HOTELCITY);
      });
    });

    this.getPricesPerNightAndTLRates();
  };

  handleChangeSuggest = (e, field, value) => {
    const { tripService } = this.props;

    this.setState({
      ...this.state,
      hotelName: {
        ...this.state.hotelName,
        label: value,
      },
      JsonData: {
        ...this.state.JsonData,
        BreakfastName: null,
        Hotel: {
          ...this.state.JsonData.Hotel,
          Name: value,
          ClassificatorId: null,
        },
      },
    }, () => {
      const hotelLabel = this.state.hotelName.label;
      if (hotelLabel) {
        tripService.autocompleteHotel(hotelLabel).then((res) => {
          const resFilter = res ? res.filter(item => !item.IsRegion) : [];
          this.setState({
            ...this.state,
            hotelName: {
              ...this.state.hotelName,
              suggests: resFilter,
            },
          });
        });
      }

      const result = tripService.hotelTripFieldValidation(FIELDS.HOTELNAMEUPPER, value);

      this.setState({
        ...this.state,
        validation: {
          ...this.state.validation,
          Hotel: {
            ...this.state.validation.Hotel,
            Name: result,
          },
        },
      });
    });
  };

  handleChangeStateInput = (field, value) => this.setState({ [field]: value });

  validationSubmit = ({ Description, Employee, JsonData, DepartmentId, ProjectId, InternalVat, vatReady, vatAmount }) => {
    const { tripService, analytics, tripAnalyticsValueIds: tripAnalyticsValues, projects, hotel } = this.props;
    const {
      UserAnalytics: serviceAnalyticsValues = [],
      mandatoryProject,
      validation,
      ReturnFunds,
      Surcharges,
      ProviderInn,
      CancellationPenalties,
      Departments,
      JsonData: { IsCustom, PriceDetails: { Base: BasePrice, Commission }, Hotel: { CheckinTime, CheckoutTime } },
      CountryCode,
    } = this.state;
    const { ProviderName, Breakfast } = JsonData;
    const oldPrice = JSON.parse(hotel.JsonData).PriceDetails;

    const calcType = InternalVat.VatReady ? InternalVat.VatInfo.CalcType : 0;
    const isAuto = HOTEL_PROVIDER_IS_AUTO_CALC_VAT.includes(JsonData.ProviderName);
    const showAmount = ((!isAuto && !vatReady) || !vatReady) && calcType !== 1;

    const projectMassage = projects.length
    ? tripService.hotelTripFieldValidation(FIELDS.PROJECTID, ProjectId)
    : ERRORSFORALL.NO_PROJECTS;

    const description = tripService.hotelTripFieldValidation('Description', Description);
    const projectId = mandatoryProject ? projectMassage : '';
    const departmentId = Departments.length > 0 ? tripService.hotelTripFieldValidation(FIELDS.DEPARTMENTID, DepartmentId) : '';
    const employees = tripService.hotelTripFieldValidation('Employees', Employee);
    const reservedNum = tripService.hotelTripFieldValidation('ReservationNumber', JsonData.ReservationNumber);
    const reservedDate = tripService.hotelTripFieldValidation('ReservationDate', JsonData.ReservationDate);
    const guest = tripService.hotelTripFieldValidation('GuestCount', JsonData.GuestCount);
    const checkin = tripService.hotelTripFieldValidation('CheckinDate', JsonData.CheckinDate);
    const name = tripService.hotelTripFieldValidation('Hotel.Name', JsonData.Hotel.Name);
    const address = tripService.hotelTripFieldValidation('Hotel.Address', JsonData.Hotel.Address);
    const city = tripService.hotelTripFieldValidation('Hotel.City', JsonData.Hotel.City);
    const inn = PROVIDERS_AVAILABLE_INN.includes(ProviderName) ?
      tripService.hotelTripFieldValidation(FIELDS.PROVIDER_INN, ProviderInn) :
      '';
    const penaltyTotal = CancellationPenalties
      ? CancellationPenalties.map(({ Total }) => (tripService.hotelTripFieldValidation('Total', Total)))
      : [];
    const penaltyFrom = CancellationPenalties
      ? CancellationPenalties.map(({ From }) => (tripService.hotelTripFieldValidation('From', momentObject(From))))
      : [];
    const penaltyBase = CancellationPenalties
      ? CancellationPenalties.map(({ Additional, Base }) => ((Additional && !Base && !IsCustom) || (typeof Base !== 'number') ? (tripService.hotelTripFieldValidation(FIELDS.BASE_PENALTY_FULL, Base)) : ''))
      : [];
    const earlyCheckIn = JsonData.CustomCheckInDate
      ? tripService.hotelTripFieldValidation('EarlyCheckIn', JsonData.PriceDetails.EarlyCheckIn)
      : '';
    const lateCheckOut = JsonData.CustomCheckOutDate
      ? tripService.hotelTripFieldValidation('LateCheckOut', JsonData.PriceDetails.LateCheckOut)
      : '';
    const breakfastName = ProviderName === HOTEL_PROVIDER_VALUE.ostrovok && CountryCode.length && CountryCode !== 'RU' && Breakfast
      ? tripService.hotelTripFieldValidation(FIELDS.BREAKFASTNAME, JsonData.BreakfastName)
      : '';

    const isNeedValid = (ReturnFunds.Enabled || Surcharges.Enabled);
    const total = penaltyTotal.filter(item => item !== '').length !== 0
      || (isNeedValid && validation.Total.filter(item => item !== '').length !== 0);
    const from = penaltyFrom.filter(item => item !== '').length !== 0;
    const base = penaltyBase.filter(item => item !== '').length !== 0
      || (isNeedValid && validation.Penalty.Base.filter(item => item !== '').length !== 0);

    const { Base: oldBase, Commission: oldCommision } = oldPrice;
    const isNeedValidVat = BasePrice !== oldBase || Commission !== oldCommision || isNeedValid;
    const vat = isNeedValidVat && showAmount && validation.HasVat;
    const checkinTime = CheckinTime.length && !timePattern.test(CheckinTime) ? ERRORS.INVALID_FORMAT : '';
    const checkoutTime = CheckoutTime.length && !timePattern.test(CheckoutTime) ? ERRORS.INVALID_FORMAT : '';

    let checkout;

    if (isMoment(JsonData.CheckinDate) &&
      JsonData.CheckinDate.isValid() &&
      isMoment(JsonData.CheckoutDate) &&
      JsonData.CheckoutDate.isValid()) {
      checkout = tripService.hotelTripFieldValidation(FIELDS.CHECKOUTDATELESS, JsonData);
    } else {
      checkout = tripService.hotelTripFieldValidation(FIELDS.CHECKOUT_DATE, JsonData.CheckoutDate);
      checkout = tripService.hotelTripFieldValidation(FIELDS.CHECKOUTDATELESS, JsonData);
    }

    const userAnalytics = validateAnalytics(tripAnalyticsValues, serviceAnalyticsValues, analytics);
    const hasUnsetRequiredAnalytics = Object.keys(userAnalytics).some(key => !!userAnalytics[key]);

    const calcTypeValue = InternalVat.VatReady ? InternalVat.VatInfo.CalcType : null;

    const getValidInternalVat = () => {
      if (!InternalVat.VatReady) return false; // VatReady = false (изначально)

      if (calcTypeValue === 1) return false; // Авторасчет

      if (InternalVat.VatReady && calcTypeValue === 2 && vatReady) return false; // Ручной ввод и нет данных НДС

      return InternalVat.VatReady && calcTypeValue === 2 && !vatReady // Если данные НДС, ручной ввод
        ? tripService.hotelTripFieldValidation(FIELDS.VAT_AMOUNT, vatAmount)
        : false;
    };

    const validInternalVat = getValidInternalVat();

    if (
      description ||
      departmentId ||
      employees ||
      reservedNum ||
      reservedDate ||
      guest ||
      checkin ||
      checkout ||
      name ||
      address ||
      city ||
      earlyCheckIn ||
      lateCheckOut ||
      total ||
      from ||
      base ||
      hasUnsetRequiredAnalytics ||
      breakfastName ||
      projectId ||
      inn ||
      validInternalVat ||
      vat ||
      checkinTime ||
      checkoutTime
    ) {
      return {
        isValid: false,
        validation: {
          ProjectId: projectId,
          Description: description,
          DepartmentId: departmentId,
          EmployeeFirst: employees,
          ReservationNumber: reservedNum,
          ReservationDate: reservedDate,
          GuestCount: guest,
          CheckinDate: checkin,
          CheckoutDate: checkout,
          Hotel: {
            Name: name,
            Address: address,
            City: city,
          },
          EarlyCheckIn: earlyCheckIn,
          LateCheckOut: lateCheckOut,
          Total: isNeedValid ? validation.Total : penaltyTotal,
          From: penaltyFrom,
          Penalty: {
            Base: isNeedValid ? validation.Penalty.Base : penaltyBase,
          },
          HasVat: isNeedValidVat && showAmount ? validation.HasVat : '',
          analytics: userAnalytics,
          BreakfastName: breakfastName,
          ProviderInn: inn,
          VatAmount: validInternalVat,
        },
        errors: {
          [FIELDS.HOTELCHECKINTIME]: checkinTime,
          [FIELDS.HOTELCHECKOUTTIME]: checkoutTime,
        },
      };
    }

    return {
      isValid: true,
    };
  };

  validationDescription = (e, field) => {
    const result = this.props.tripService.hotelTripFieldValidation(field, this.state.Description);

    this.setState((state) => {
      const newState = state;
      newState.validation[field] = result;

      return newState;
    });
  };

  validateInput = (e, field) => {
    const part = field.split('.');

    if (part.length === 1) {
      const result = this.props.tripService.hotelTripFieldValidation(field, this.state.JsonData[field]);

      this.setState((state) => {
        const newState = state;
        newState.validation[field] = result;

        return newState;
      });
    } else {
      const result = this.props.tripService.hotelTripFieldValidation(field, this.state.JsonData[part[0]][part[1]]);

      this.setState((state) => {
        const newState = state;
        newState.validation[part[0]][part[1]] = result;

        return newState;
      });
    }
  };

  validateDate = (field) => {
    const { JsonData } = this.state;
    const { tripService } = this.props;
    const { CheckinDate, CheckoutDate } = JsonData;

    let result;
    let resultCheckin;
    let resultCheckout;
    let resultCheckoutLess;

    const isDateBeforeOtherDate = !dateAfterOtherDate(JsonData);

    if (isDateBeforeOtherDate && field === FIELDS.CHECKOUT_DATE) {
      resultCheckin = tripService.hotelTripFieldValidation(FIELDS.CHECKIN_DATE_EDIT, JsonData);
      resultCheckoutLess = tripService.hotelTripFieldValidation(FIELDS.CHECKOUTDATELESS, JsonData);
    } else if ((field === FIELDS.CHECKIN_DATE || field === FIELDS.CHECKOUT_DATE)
      && (CheckinDate || CheckoutDate)
      && (isValidMomentObject(CheckinDate) || isValidMomentObject(CheckoutDate))) {
      resultCheckin = tripService.hotelTripFieldValidation(FIELDS.CHECKIN_DATE_EDIT, JsonData);
      resultCheckout = tripService.hotelTripFieldValidation(FIELDS.CHECKOUT_DATE_EDIT, JsonData);
      resultCheckoutLess = tripService.hotelTripFieldValidation(FIELDS.CHECKOUTDATELESS, JsonData);
    } else {
      result = tripService.hotelTripFieldValidation(field, JsonData[field]);
    }
    if (resultCheckin || resultCheckout || resultCheckoutLess) {
      this.setState({
        validation: {
          ...this.state.validation,
          [field]: result,
          CheckinDate: resultCheckin,
          CheckoutDate: resultCheckout || resultCheckoutLess,
        },
      });
    } else {
      this.setState({
        validation: {
          ...this.state.validation,
          [field]: result,
        },
      });
    }
  };

  validateProviderInn = (e, field) => {
    const { ProviderInn, validation } = this.state;

    const result = this.props.tripService.hotelTripFieldValidation(field, ProviderInn);

    this.setState({
      validation: {
        ...validation,
        ProviderInn: result,
      },
    });
  }

  closeAlertTP = () => this.setState({ showAlertTP: false });

  handleAddEmployee = () => {
    const newEmployeesList = [...this.state.EmployeesList];

    newEmployeesList.push({});

    this.setState({
      EmployeesList: newEmployeesList,
    });
  };

  handleRemoveEmployee = () => {
    const { EmployeesList, DepartmentId, Departments } = this.state;
    const newEmployeesList = [...EmployeesList];

    newEmployeesList.pop();

    this.setState({
      EmployeesList: newEmployeesList,
      DepartmentId: !newEmployeesList.length ? 0 : DepartmentId,
      Departments: !newEmployeesList.length ? [] : Departments,
    });
  };

  handleChangePenaltyInput = (field, value, i) => {
    const { ReturnFunds, Surcharges, validation, CancellationPenalties } = this.state;
    const { tripService } = this.props;

    const isNeedValid = (ReturnFunds.Enabled || Surcharges.Enabled)
      && (field === FIELDS.TOTAL_PENALTY || field === FIELDS.BASE_PENALTY);

    if (isNeedValid) {
      const newValidation = validation;

      if (field === FIELDS.TOTAL_PENALTY) {
        newValidation.Total[i] = tripService.hotelTripFieldValidation(field, value);
      } else {
        const currentP = CancellationPenalties[i];
        newValidation.Penalty.Base[i] = (currentP.Additional && !value && !currentP.IsCustom) || (typeof value !== 'number')
          ? (tripService.hotelTripFieldValidation(FIELDS.BASE_PENALTY_FULL, value))
          : '';
      }

      this.setState({ validation: newValidation });
    }

    this.setState((state) => {
      const newState = state;

      newState.CancellationPenalties.map((item, index) => {
        if (index === i) {
          const penalty = item;
          penalty[field] = value;
          return penalty;
        }
        return item;
      });

      return newState;
    });
  }

  handleCloseAlertBookingDate = () => this.setState({ showAlertBookingDate: false });

  // определение тарифа:
  // 1 Невозвратный без завтрака
  // 2 Невозвратный с завтраком
  // 3 Возвратный без завтрака
  // 4 Возвратный с завтраком
  definitionRatePlanId = (CancellationPenalties, Breakfast, IsCustom) => {
    let PlanId;

    if ((typeof CancellationPenalties === 'object' && CancellationPenalties.length !== 0) || IsCustom) {
      PlanId = Breakfast ? 4 : 3;

      return PlanId;
    }

    if ((typeof CancellationPenalties !== 'object' || CancellationPenalties.length === 0) && !IsCustom) {
      PlanId = Breakfast ? 2 : 1;
    }

    return PlanId;
  };

  handleRecalculateButton = async () => {
    const {
      state: {
        JsonData: {
          IsCustom,
          ReservationNumber,
          ProviderName,
          GuestCount,
          Hotel: { ClassificatorId, CheckinTime, CheckoutTime },
          CheckinDate,
          CheckoutDate,
          CustomCheckInDate,
          CustomCheckOutDate,
        },
        ratePlaneId,
      },
      props: {
        notificationsService,
        tripService,
        accountId,
      },
    } = this;

    const PlanId = this.state.planId;
    const checkinHoursAndMinutes = CheckinTime.split(':');
    const checkoutHoursAndMinutes = CheckoutTime.split(':');
    const fullCheckInDate = momentAddHoursMinutes(CheckinDate, checkinHoursAndMinutes[0], checkinHoursAndMinutes[1]);
    const fullCheckOutDate = momentAddHoursMinutes(CheckoutDate, checkoutHoursAndMinutes[0], checkoutHoursAndMinutes[1]);

    const dataPayload = {
      HotelId: parseInt(ClassificatorId, 10),
      IsCustom: IsCustom || false,
      AccountId: accountId,
      NumberOfGuests: parseInt(GuestCount, 10),
      CustomCheckIn: CustomCheckInDate,
      CustomCheckOut: CustomCheckOutDate,
      CheckInDate: formatDate(fullCheckInDate, DATE_WITHOUT_TIME_ZONE),
      CheckOutDate: formatDate(fullCheckOutDate, DATE_WITHOUT_TIME_ZONE),
      BookInfo: {
        RoomTypeId: ratePlaneId,
        PlanId,
      },
    };

    const result = await tripService.recalculateTripAmount(ProviderName, ReservationNumber, dataPayload);

    if (result) {
      const { RateInfo } = result;
      const { CancellationPolicy: { Penalties, DeadLine }, BookInfo: { RoomTypeId }, Price } = RateInfo;
      const { Base, TotalPrice, Commission, PerDayPrices, EarlyCheckIn, LateCheckOut } = Price;

      const validatedDatePenalties = Penalties ? Penalties.map(({ From, Base: base, Total, Additional }) => ({
        From: momentObject(From),
        Base: base,
        Total: Math.trunc(Total),
        Additional,
      })) : [];

      this.setState({
        ...this.state,
        CancellationPenalties: validatedDatePenalties,
        JsonData: {
          ...this.state.JsonData,
          PriceDetails: {
            ...this.state.JsonData.PriceDetails,
            Base,
            EarlyCheckIn,
            LateCheckOut,
            Total: TotalPrice,
            Commission,
          },
          PerDayPrices,
          Room: {
            ...this.state.JsonData.Room,
            FreeCancellation: DeadLine ? momentObject(DeadLine) : DeadLine,
          },
        },
        ratePlaneId: RoomTypeId,
      });
    }

    if (!result) {
      notificationsService.send({
        header: 'Возникла ошибка при перерасчете',
        level: notificationsService.levels.get('FAILURE'),
        message: 'Не удалось получить цену на эти даты, категорию номера, кол-во гостей и тариф. Попробуйте поменять один из параметров.',
      });
    }
  };

  handleChangeTimeInput(e, field, value) {
    const { JsonData } = this.state;

    if (value.length === 0 || numbersAndColon.test(value)) {
      this.setState(prevState => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          [field]: '',
        },
      }));
      const part = field.split('.');
      JsonData[part[0]][part[1]] = value;

      this.setState({ JsonData });
    } else {
      this.setState(prevState => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          [field]: ERRORS.INVALID_CHARACTER,
        },
      }));
    }
  }

  handleBlurTimeInput(e, field) {
    const value = e.target.value;

    if (value.length === 0 || timePattern.test(value)) {
      const { JsonData } = this.state;
      const { tripService } = this.props;

      const isDateBeforeOtherDate = !dateAfterOtherDate(JsonData);

      if (isDateBeforeOtherDate) {
        const resultCheckoutLess = tripService.hotelTripFieldValidation(FIELDS.HOTEL_CHECKOUT_TIME_LESS, JsonData);

        return this.setState(prevState => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            [FIELDS.HOTELCHECKOUTTIME]: resultCheckoutLess,
          },
        }));
      }

      return this.setState(prevState => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          [field]: '',
          [FIELDS.HOTELCHECKOUTTIME]: '',
        },
      }));
    }

    return this.setState(prevState => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [field]: ERRORS.INVALID_FORMAT,
      },
    }));
  }

  handleChangeVatReady = (value) => {
    this.setState({
      vatReady: value,
      validation: {
        ...this.state.validation,
        HasVat: '',
      },
    });
  };

  handleChangeAmount = (value) => {
    this.setState({
      vatAmount: value,
      validation: {
        ...this.state.validation,
        HasVat: '',
      },
    });
  };

  renderAlertTP = () => (this.state.showAlertTP ? <AlertTP onClose={ this.closeAlertTP } /> : null);

  renderNights = () => {
    const { JsonData: {
      PerDayPrices = [],
      ProviderName,
    } } = this.state;
    const nights = PerDayPrices.map(({ Date, Price }) => ({
      Date: Date !== null ? formatDate(Date) : null,
      Price,
    }));

    return ProviderName === HOTEL_PROVIDER_VALUE.travelline && (
      <div>
        <Nights
          nights={ nights }
          onAddNight={ this.handleAddNights }
          onRemoveNight={ this.handleRemoveNights }
          onChangeNight={ this.handleChangeNights }
        />
        <AjaxButton
          label={ BUTTONS.RECALCULATE }
          onClick={ this.handleRecalculateButton }
        />
      </div>
    );
  };

  renderWarning = (show) => {
    if (!show) {
      return null;
    }

    return (
      <span className={ styles.error_fin_operation }>{FUNDSLABELS.WARNING}</span>
    );
  }

  renderAlertBookingDate = () => {
    const { showAlertBookingDate } = this.state;

    if (!showAlertBookingDate) return null;

    return (
      <Dialog onClick={ this.handleCloseAlertBookingDate }>
        <div className={ styles.dialog }>
          <p>{ LABELS.ALERT_BOOKING_DATE }</p>
          <div className={ styles.actions }>
            <Button
              label={ LABELS.OK }
              theme={ FLAT }
              onClick={ this.handleCloseAlertBookingDate }
            />
          </div>
        </div>
      </Dialog>
    );
  }

  renderCurrency = () => {
    const { CurrencyInfo, JsonData: { ProviderName }, isExpediaOrTripcomProvider } = this.state;

    if (!isExpediaOrTripcomProvider || !CurrencyInfo) return null;

    const currencyInfo = {
      ...CurrencyInfo,
      MFInCurrency: CurrencyInfo.MF,
      MF: CurrencyInfo.MFInRUB,
    };

    return (
      <Currency
        currencyInfo={ currencyInfo }
        providerName={ ProviderName }
        disabledEdit
        isEdit
      />
    );
  };

  render() {
    const {
      Description,
      Penalties,
      ReturnFunds,
      Surcharges,
      EmployeesList,
      validation,
      travellers,
      DepartmentId,
      EmployeeId,
      ProjectId,
      Departments,
      JsonData,
      Contact,
      CancellationPenalties,
      UserAnalytics,
      rates,
      ratePlaneId,
      hotelName,
      CustomHotelName,
      errors,
      CountryCode,
      mandatoryProject,
      isExpediaOrTripcomProvider,
      ProviderInn,
      ReportAmount,
      vatReady,
      vatAmount,
      disabledVatReady,
      PriceDetails: PriceDetailsData,
      InternalVat,
      Meal,
    } = this.state;

    const {
      projects,
      analytics,
      tripAnalyticsValueIds,
      tripService,
    } = this.props;
    const { PriceDetails } = JsonData;
    const { Base, Commission, Fee, Tax } = PriceDetails;

    const hotelProps = {
      employeesList: EmployeesList,
      employees: this.state.employeesList,
      travellers,
      departments: Departments,
      hotelName,
      projects,
      providerNameOptions: this.providerNameOptions,
      departmentId: DepartmentId,
      employeeId: EmployeeId,
      projectId: ProjectId,
      CustomHotelName,
      contact: Contact,
      ProviderInn,
      ReportAmount,
    };

    const funcProps = {
      onAddEmployee: this.handleAddEmployee,
      onRemoveEmployee: this.handleRemoveEmployee,
      onRemoveEmployeeSuggest: this.handleRemoveEmployeeSuggest,
      onSelectEmployeeSuggest: this.handleSelectEmployee,
      onChangeEmployeeSuggest: this.handleChangeEmployee,
      onEmployeeFocusSuggest: this.handleEmployeeFocus,
      onSelectDepartment: this.handleSelectDepartment,
      onSelectProject: this.handleSelectProject,
      onChangeJsonInput: this.handleChangeJsonInput,
      onChangeNumberInput: (e, field, value) => this.handleChangeNumberInput(field, value),
      onChangeStateInput: (e, field, value) => this.handleChangeStateInput(field, value),
      onChangeRoomCategory: this.handleChangeRoomCategory,
      onChangeProviderName: this.handleChangeProviderName,
      onChangeInput: this.handleChangeInput,
      onValidateInput: this.validateInput,
      onValidateInn: this.validateProviderInn,
      onChangeTimeInput: (e, field, value) => this.handleChangeTimeInput(e, field, value),
      onBlurTimeInput: (e, field) => this.handleBlurTimeInput(e, field),
      onChangeDate: this.handleChangeDate,
      onValidateDate: this.validateDate,
      onSelectSuggest: this.handleSelectSuggest,
      onChangeSuggest: this.handleChangeSuggest,
      onChangeECLCCheckbox: this.handleChangeECLCCheckbox,
      onChangePriceDetailsInput: this.handleChangePriceDetailsInput,
      onChangeCheckbox: this.handleChangeCheckbox,
      onAddCancellation: this.handleAddCancellation,
      onRemoveCancellation: this.handleRemoveCancellation,
      onChangePenaltyInput: this.handleChangePenaltyInput,
      onResetMainHotelFields: this.resetMainHotelFields,
    };

    const penaltiesFormHtml = Penalties.Enabled ?
      renderFundsForm(Penalties, this.handlerChangePenalties, FUNDSFIELDSTYPES.PENALTIES)
      : null;

    const returnFundsFormHtml = ReturnFunds.Enabled ?
      renderFundsForm(ReturnFunds, this.handlerChangeReturnFunds, FUNDSFIELDSTYPES.RETURNFUNDS, SERVICETYPE.HOTEL)
      : null;

    const surchargesFormHtml = Surcharges.Enabled ?
      renderFundsForm(Surcharges, this.handlerChangeSurcharges, FUNDSFIELDSTYPES.SURCHARGES)
      : null;

    const disablePenalties = Surcharges.Enabled;
    const disableReturn = Surcharges.Enabled;
    const disableSurcharges = ReturnFunds.Enabled || Penalties.Enabled;
    const calcType = InternalVat.VatReady ? InternalVat.VatInfo.CalcType : 0;
    const hasVat = PriceDetails.HasVAT ? VATVALUE.WITH : VATVALUE.WITHOUT;

    return (
      <div className={ styles.wrap }>
        <div className={ `${styles.row} ${styles.panel}` }>
          <h4>Описание изменения для истории изменения заказа</h4>
          <div className={ styles.row }>
            <Input
              field={ FIELDS.DESCRIPTION }
              value={ Description }
              label={ TRIPSLABELS.DESCRIPTION }
              onChange={ this.handleChangeInput }
              onBlur={ this.validationDescription }
              valid={ validation.Description }
            />
          </div>
        </div>
        <CommonFields
          analytics={ analytics }
          tripAnalyticsValues={ tripAnalyticsValueIds }
          serviceAnalyticsValues={ UserAnalytics }
          hotelProps={ hotelProps }
          data={ JsonData }
          rates={ rates }
          ratePlaneId={ ratePlaneId }
          validation={ validation }
          priceDetails={ PriceDetails }
          meal={ Meal }
          funcProps={ funcProps }
          cancellationPenalties={ CancellationPenalties }
          handleSelectAnalytics={ this.handleSelectAnalytics }
          errors={ errors }
          country={ CountryCode }
          tripService={ tripService }
          updateMainHotelFields={ this.handleUpdateMainHotelFields }
          mandatoryProject={ mandatoryProject }
        />
        <div className={ `${styles.row} ${styles.panel}` }>
          <h4>{LABELS.FINANCE_OPERATIONS}</h4>
          <div className={ styles.row }>
            <div className={ styles['col-1-3'] }>
              <Checkbox
                field={ FUNDSFIELDS.PENALTIES }
                label={ FUNDSLABELS.PENALTIES }
                value={ Penalties.Enabled }
                disabled={ isExpediaOrTripcomProvider || disablePenalties }
                onChange={ this.handleChangeFinancesEnabled }
              />
              {penaltiesFormHtml}
            </div>
            <div className={ styles['col-1-3'] }>
              <Checkbox
                field={ FUNDSFIELDS.RETURNFUNDS }
                label={ FUNDSLABELS.RETURNFUNDS }
                value={ ReturnFunds.Enabled }
                disabled={ isExpediaOrTripcomProvider || disableReturn }
                onChange={ this.handleChangeFinancesEnabled }
              />
              {this.renderWarning(ReturnFunds.Enabled)}
              {returnFundsFormHtml}
            </div>

            <div className={ styles['col-1-3'] }>
              <Checkbox
                field={ FUNDSFIELDS.SURCHARGES }
                label={ FUNDSLABELS.SURCHARGES }
                value={ Surcharges.Enabled }
                disabled={ isExpediaOrTripcomProvider || disableSurcharges }
                onChange={ this.handleChangeFinancesEnabled }
              />
              {this.renderWarning(Surcharges.Enabled)}
              {surchargesFormHtml}
            </div>
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-attention'] }>
            <Input
              field={ PRICEFIELDSSHORT.BASE }
              value={ Base }
              label={ PRICELABELS.BASE }
              onChange={ this.handleChangePriceDetailsInput }
              disabled={ isExpediaOrTripcomProvider }
              type={ NUMBER }
              onKeyDown={ preventKeyNotNumber }
            />
          </div>
          <div className={ styles['col-1-4'] }>
            <Input
              field={ PRICEFIELDSSHORT.COMMISSION }
              value={ Commission }
              label={ PRICELABELS.COMMISSIONSMARTWAY }
              onChange={ this.handleChangePriceDetailsInput }
              disabled={ isExpediaOrTripcomProvider }
              type={ NUMBER }
              onKeyDown={ preventKeyNotNumber }
            />
          </div>
          <div className={ styles['col-1-4'] }>
            <Input
              field={ PRICEFIELDSSHORT.FEE }
              value={ Fee }
              label={ PRICELABELS.FEE }
              onChange={ this.handleChangePriceDetailsInput }
              disabled={ isExpediaOrTripcomProvider }
              type={ NUMBER }
              onKeyDown={ preventKeyNotNumber }
            />
          </div>
        </div>
        { this.renderCurrency() }
        <div className={ styles.row }>
          <div className={ styles['col-1-4'] }>
            <Input
              field={ PRICEFIELDSSHORT.TAX }
              value={ Tax }
              label={ PRICELABELS.TAX }
              onChange={ this.handleChangePriceDetailsInput }
              disabled={ isExpediaOrTripcomProvider }
              type={ NUMBER }
              onKeyDown={ preventKeyNotNumber }
            />
          </div>
        </div>
        { this.renderNights() }
        <div className={ styles.row }>
          <div className={ styles['col-1-3'] } />
          <div className={ styles['col-1-2'] }>
            <VatBlock
              isEdit
              showAddButton={ false }
              calcType={ calcType }
              providerName={ JsonData.ProviderName }
              vatReady={ vatReady }
              vatAmount={ vatAmount }
              disabledVatReady={ disabledVatReady }
              validation={ validation }
              base={ PriceDetailsData.Base }
              hasVat={ hasVat }
              onChangeVatReady={ this.handleChangeVatReady }
              onChangeAmount={ (e, field, value) => this.handleChangeAmount(value) }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-3'] }>
            <FlatButton
              label={ TRIPSLABELS.CONFIRM }
              onClick={ this.handleConfirm }
            />
          </div>
        </div>
        { this.renderAlertTP() }
        { this.renderAlertBookingDate() }
      </div>
    );
  }
}

export default HotelForm;
