import React from 'react';
import PropTypes from 'prop-types';
import { MultiSelect } from 'sw-ui';

import { filterMeal } from '../../../../../../bi/utils/hotels';

import { FIELDS } from '../../../../../../bi/constants/trips';

import styles from '../../../../styles/form.module.scss';

const LABELS = {
  SELECT_MEAL: 'Выберите питание',
};

const MealFields = ({
  meal,
  onChangeStateInput,
}) => {
  const filteredItems = filterMeal(meal);

  const handleChangeMeal = (field, value) => {
    const newList = value.includes(4) && !value.includes(1)
      ? value.filter(item => item !== 4)
      : value;

    return onChangeStateInput(null, field, newList);
  };

  return (
    <div className={ styles['col-1-4'] }>
      <MultiSelect
        label={ LABELS.SELECT_MEAL }
        items={ filteredItems }
        values={ meal }
        onChange={ value => handleChangeMeal(FIELDS.MEAL, value) }
      />
    </div>
  );
};

MealFields.propTypes = {
  meal: PropTypes.array.isRequired,
  onChangeStateInput: PropTypes.func.isRequired,
};

export { MealFields };
