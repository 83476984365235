import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog } from 'sw-ui';

import styles from '../../../../styles/trip.module.scss';

const LABELS = {
  TEXT: 'Не удалось выполнить операцию',
  CLOSE: 'Закрыть',
};

const VatErrorDialog = ({
  text = LABELS.TEXT,
  onClose,
}) => (
  <Dialog onClick={ onClose }>
    <div className={ styles['user-dialog'] }>
      <p className={ styles['dialog-item'] }>
        {text}
      </p>
      <div className={ `${styles.row} ${styles.right}` }>
        <Button label={ LABELS.CLOSE } theme='flat' onClick={ onClose } />
      </div>
    </div>
  </Dialog>
);

VatErrorDialog.propTypes = {
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { VatErrorDialog };
