import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, Checkbox } from 'sw-ui';

import Input from '../../../../../../components/input';
import Radio from '../../../../../../components/radio';

import COMPONENTS from '../../../../../../bi/constants/components';
import { HOTEL_PROVIDER_IS_AUTO_CALC_VAT } from '../../../../../../bi/constants/hotel';
import { TRIPSLABELS } from '../../../../../../bi/constants/trips';
import { VATVALUE } from '../../../../../../bi/constants/hotels';

import { AttentionVat } from '../../attentionVat';

import styles from '../../../../styles/form.module.scss';


const LABELS = {
  TOOLTIP: 'Поле цена не заполнено',
  AMOUNT: 'Сумма НДС от поставщика',
};

const VatBlock = ({
  isEdit,
  isItemCart,
  showAddButton,
  providerName,
  vatReady,
  vatAmount,
  validation,
  disabledVatReady,
  base,
  hasVat,
  calcType,
  onAddVat,
  onChangeVatReady,
  onChangeAmount,
  onChangeHasVat,
}) => {
  const disabledButton = !base;
  const disabledAmount = !isEdit || (isEdit && vatReady) || calcType !== 2;

  const isAuto = HOTEL_PROVIDER_IS_AUTO_CALC_VAT.includes(providerName);

  const showAmount = ((!isAuto && !vatReady) || !vatReady) && calcType !== 1;
  const showVatReady = calcType !== 1;

  const renderTooltip = () => {
    if (!disabledButton) return null;

    return (
      <Tooltip position={ COMPONENTS.TOOLTIP.POSITION.TOP }>
        { LABELS.TOOLTIP }
      </Tooltip>
    );
  };

  const renderCheckboxVatReady = () => {
    if (!showVatReady || isItemCart) return null;

    return (
      <Checkbox
        value={ vatReady }
        onChange={ onChangeVatReady }
        disabled={ disabledVatReady }
      >
        { TRIPSLABELS.NOT_DATA_VAT }
      </Checkbox>
    );
  };

  const renderAddButton = () => {
    if (!showAddButton || isItemCart) return null;

    return (
      <div className='sw-tooltip-wrapper'>
        { renderTooltip() }
        <Button
          label={ TRIPSLABELS.ADD_DATA_VAT }
          theme={ COMPONENTS.BUTTON.THEME.SECOND }
          className={ styles.vat_button }
          onClick={ onAddVat }
          disabled={ disabledButton }
        />
      </div>
    );
  };

  const renderInputAmount = () => {
    if (!showAmount || isItemCart) return null;

    return (
      <div className={ `${styles['col-1-2']} ${validation.VatAmount ? styles['no-valid-hotel-name'] : ''}` }>
        <Input
          field='Amount'
          value={ vatAmount }
          type='number'
          label={ LABELS.AMOUNT }
          onChange={ onChangeAmount }
          disabled={ disabledAmount }
          valid={ validation.VatAmount }
        />
      </div>
    );
  };

  return (
    <div className={ `${styles['vat-action']}` }>
      <div className={ `${validation.HasVat ? styles.error : ''}` }>
        <Radio
          disabled={ !isItemCart }
          label={ TRIPSLABELS.HASVAT }
          field='hasVat'
          checked={ hasVat === VATVALUE.WITH }
          value={ VATVALUE.WITH }
          onChange={ (field, value) => onChangeHasVat(value) }
        />
        <Radio
          disabled={ !isItemCart }
          label={ TRIPSLABELS.NOTVAT }
          field='hasVat'
          checked={ hasVat === VATVALUE.WITHOUT }
          value={ VATVALUE.WITHOUT }
          onChange={ (field, value) => onChangeHasVat(value) }
        />
        { renderCheckboxVatReady() }
        { renderAddButton() }
      </div>
      <span className='error-msg'>{validation.HasVat}</span>
      <AttentionVat providerName={ providerName } />
      { renderInputAmount() }
    </div>
  );
};

VatBlock.propTypes = {
  providerName: PropTypes.string.isRequired,
  vatReady: PropTypes.string.isRequired,
  vatAmount: PropTypes.string.isRequired,
  base: PropTypes.number.isRequired,
  hasVat: PropTypes.string.isRequired,
  onChangeVatReady: PropTypes.func,
  onAddVat: PropTypes.func,
  onChangeAmount: PropTypes.func,
  onChangeHasVat: PropTypes.func,
  validation: PropTypes.object.isRequired,
  disabledVatReady: PropTypes.bool,
  showAddButton: PropTypes.bool,
  isEdit: PropTypes.bool,
  isItemCart: PropTypes.bool,
  calcType: PropTypes.number,
};

VatBlock.defaultProps = {
  onChangeVatReady: () => {},
  onAddVat: () => {},
  showAddButton: true,
  onChangeAmount: () => {},
  onChangeHasVat: () => {},
  disabledVatReady: false,
  isEdit: false,
  isItemCart: false,
  calcType: 0,
};

export { VatBlock };
